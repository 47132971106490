/**
 * 配置store仓库信息
 * Author: 李永峰
 * Time: 2023-08-23
 */

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  defaultAvatar: 'https://img0.baidu.com/it/u=3138001596,2686703988&fm=253&fmt=auto&app=138&f=JPEG?w=200&h=200',
  userInfo: JSON.parse(window.localStorage.getItem('user-info') || '{}'),
  token: window.localStorage.getItem('token') || '',
  cateList: JSON.parse(window.localStorage.getItem('cate') || '[]')
}

const store = new Vuex.Store({
  state,
  getters: {},
  actions: {},
  mutations: {
    // 更新用户信息
    updateUserInfo (state, { data, callback }) {
      state.userInfo = data
      this.commit('saveUserInfoLocalStorage')
      callback && callback()
    },
    // 更新用户token
    // updateToken (state, { data, callback }) {
    //   state.token = data
    //   this.commit('saveTokenLocalStorage')
    //   callback && callback()
    // },
    // 更新分类列表
    // updateCateList (state, { data, callback }) {
    //   state.cateList = data
    //   this.commit('saveCateListLocalStorage')
    //   callback && callback()
    // },
    // 用户信息存储到本地存储中
    saveUserInfoLocalStorage (state) {
      window.localStorage.setItem('user-info', JSON.stringify(state.userInfo))
    },
    // 存储token到本地存储中
    // saveTokenLocalStorage (state) {
    //   window.localStorage.setItem('token', JSON.stringify(state.token))
    // },
    // 存储分类列表到本地存储中
    // saveCateListLocalStorage (state) {
    //   window.localStorage.setItem('cate', JSON.stringify(state.cateList))
    // }
  },
  modules: {}
})

export default store
