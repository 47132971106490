/**
 * 配置路由映射关系表
 * Author: 李永峰
 * Time: 2023-08-22
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

// 路由懒加载
const Login = () => import('views/login/')
const Layout = () => import('views/layout/')
const Home = () => import('@/views/home/')
const Process = () => import('@/views/process/')
const Enterprise = () => import('@/views/enterprise/')
const Configure = () => import('@/views/configure/')
const Stencil = () => import('@/views/stencil/')
const Profile = () => import('@/views/profile/')

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home
      },
      // 基础配置
      {
        path: '/configure',
        name: 'configure',
        component: Configure
      },
      {
        path: '/process',
        name: 'process',
        component: Process
      },
      {
        path: '/enterprise',
        name: 'enterprise',
        component: Enterprise
      },
      {
        path: '/stencil',
        name: 'stencil',
        component: Stencil
      },
      {
        path: '/profile',
        name: 'profile',
        component: Profile
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
